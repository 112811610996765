$color-purple: #403a91;
$color-blue: #13ade9;

.page {
  width: 100vw;
  overflow-x: hidden;

  &-header {
    background-color: $color-purple;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;

    svg {
      margin-right: 5px;
    }
  }

  .message {
    text-align: center;
    padding: 20px 0;
  }

  .action-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;

    button {
      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }

  .button-purple {
    background: $color-purple;
  }

  &.page-result {
    .page-content {
      text-align: center;
      padding: 10px 40px;

      .message {
        padding: 5px 0;
      }
    }

    .tcp-form {
      background-color: #eee;
      padding-bottom: 20px;
      margin: 10px 0;
      border-top: 2px solid #ddd;
      border-bottom: 2px solid #ddd;
    }
  }
}

.a2hs__container {
  .a2hs__text {
    svg {
      width: 15px;
      fill: #06c;
    }
  }
}
